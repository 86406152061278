define("discourse/plugins/discourse-data-explorer/discourse/controllers/admin-plugins-explorer-index", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "discourse/lib/ajax-error", "discourse/lib/decorators", "discourse-i18n"], function (_exports, _tracking, _controller, _object, _service, _rsvp, _ajaxError, _decorators, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PluginsExplorerController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "sortByProperty", [_tracking.tracked], function () {
      return "last_run_at";
    }))();
    #sortByProperty = (() => (dt7948.i(this, "sortByProperty"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "sortDescending", [_tracking.tracked], function () {
      return true;
    }))();
    #sortDescending = (() => (dt7948.i(this, "sortDescending"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "params", [_tracking.tracked]))();
    #params = (() => (dt7948.i(this, "params"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "search", [_tracking.tracked]))();
    #search = (() => (dt7948.i(this, "search"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "newQueryName", [_tracking.tracked]))();
    #newQueryName = (() => (dt7948.i(this, "newQueryName"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "showCreate", [_tracking.tracked]))();
    #showCreate = (() => (dt7948.i(this, "showCreate"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    queryParams = ["id"];
    explain = false;
    acceptedImportFileTypes = ["application/json"];
    order = null;
    form = null;
    get sortedQueries() {
      const sortedQueries = this.model.sortBy(this.sortByProperty);
      return this.sortDescending ? sortedQueries.reverse() : sortedQueries;
    }
    get parsedParams() {
      return this.params ? JSON.parse(this.params) : null;
    }
    get filteredContent() {
      const regexp = new RegExp(this.search, "i");
      return this.sortedQueries.filter(result => regexp.test(result.name) || regexp.test(result.description));
    }
    get createDisabled() {
      return (this.newQueryName || "").trim().length === 0;
    }
    addCreatedRecord(record) {
      this.model.pushObject(record);
      this.router.transitionTo("adminPlugins.explorer.queries.details", record.id);
    }
    async _importQuery(file) {
      const json = await this._readFileAsTextAsync(file);
      const query = this._parseQuery(json);
      const record = this.store.createRecord("query", query);
      const response = await record.save();
      return response.target;
    }
    _parseQuery(json) {
      const parsed = JSON.parse(json);
      const query = parsed.query;
      if (!query || !query.sql) {
        throw new TypeError();
      }
      query.id = 0; // 0 means no Id yet
      return query;
    }
    _readFileAsTextAsync(file) {
      return new _rsvp.Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsText(file);
      });
    }
    dragMove(e) {
      if (!e.movementY && !e.movementX) {
        return;
      }
      const editPane = document.querySelector(".query-editor");
      const target = editPane.querySelector(".panels-flex");
      const grippie = editPane.querySelector(".grippie");

      // we need to get the initial height / width of edit pane
      // before we manipulate the size
      if (!this.initialPaneWidth && !this.originalPaneHeight) {
        this.originalPaneWidth = target.clientWidth;
        this.originalPaneHeight = target.clientHeight;
      }
      const newHeight = Math.max(this.originalPaneHeight, target.clientHeight + e.movementY);
      const newWidth = Math.max(this.originalPaneWidth, target.clientWidth + e.movementX);
      target.style.height = newHeight + "px";
      target.style.width = newWidth + "px";
      grippie.style.width = newWidth + "px";
      this.appEvents.trigger("ace:resize");
    }
    static #_11 = (() => dt7948.n(this.prototype, "dragMove", [_decorators.bind]))();
    scrollTop() {
      window.scrollTo(0, 0);
    }
    static #_12 = (() => dt7948.n(this.prototype, "scrollTop", [_decorators.bind]))();
    async import(files) {
      try {
        this.loading = true;
        const file = files[0];
        const record = await this._importQuery(file);
        this.addCreatedRecord(record);
      } catch (e) {
        if (e.jqXHR) {
          (0, _ajaxError.popupAjaxError)(e);
        } else if (e instanceof SyntaxError) {
          this.dialog.alert((0, _discourseI18n.i18n)("explorer.import.unparseable_json"));
        } else if (e instanceof TypeError) {
          this.dialog.alert((0, _discourseI18n.i18n)("explorer.import.wrong_json"));
        } else {
          this.dialog.alert((0, _discourseI18n.i18n)("errors.desc.unknown"));
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } finally {
        this.loading = false;
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "import", [_object.action]))();
    displayCreate() {
      this.showCreate = true;
    }
    static #_14 = (() => dt7948.n(this.prototype, "displayCreate", [_object.action]))();
    resetParams() {
      this.selectedItem.resetParams();
    }
    static #_15 = (() => dt7948.n(this.prototype, "resetParams", [_object.action]))();
    updateSortProperty(property) {
      if (this.sortByProperty === property) {
        this.sortDescending = !this.sortDescending;
      } else {
        this.sortByProperty = property;
        this.sortDescending = true;
      }
    }
    static #_16 = (() => dt7948.n(this.prototype, "updateSortProperty", [_object.action]))();
    async create() {
      try {
        const name = this.newQueryName.trim();
        this.loading = true;
        this.showCreate = false;
        const result = await this.store.createRecord("query", {
          name
        }).save();
        this.addCreatedRecord(result.target);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.loading = false;
      }
    }
    static #_17 = (() => dt7948.n(this.prototype, "create", [_object.action]))();
    updateSearch(value) {
      this.search = value;
    }
    static #_18 = (() => dt7948.n(this.prototype, "updateSearch", [_object.action]))();
    updateNewQueryName(value) {
      this.newQueryName = value;
    }
    static #_19 = (() => dt7948.n(this.prototype, "updateNewQueryName", [_object.action]))();
  }
  _exports.default = PluginsExplorerController;
});